import React from 'react';
import { TwitterShareButton, TwitterIcon, LineShareButton, LineIcon, FacebookShareButton, FacebookIcon } from 'react-share';

import "./share.css"

const SharingButtons = ({ title, url }) => {
  return (
    <div className="sharing-buttons">
      <FacebookShareButton url={url}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>

      <LineShareButton url={url}>
        <LineIcon size={32} round />
      </LineShareButton>

      <TwitterShareButton title={title} url={url}>
        <TwitterIcon size={32} round />
      </TwitterShareButton>
    </div>
  );
};

export default SharingButtons;