import React from "react"
import { Link, graphql } from "gatsby"

import BlogLayout from "../components/blog-layout"
import SEO from "../components/seo"
import SharingButtons from "../components/share"

import "./typography.css"

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark;
    const siteTitle = data.site.siteMetadata.title;
    const { previous, next } = pageContext;
    console.warn(post.fields.tags);

    return (
        <BlogLayout location={location} title={siteTitle}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <article className="main-contents">
                <header>
                    <h1 style={{
                        marginBottom: 0,
                    }}>
                        {post.frontmatter.title}
                    </h1>
                    <p style={{
                        display: `block`
                    }}>
                        {post.frontmatter.date}
                    </p>
                </header>
                <section dangerouslySetInnerHTML={{ __html: post.html }} />
            </article>

            <SharingButtons url={`${data.site.siteMetadata.origin}/posts${post.fields.slug}`} title={post.frontmatter.title} />
            <p className="tag-area">Tags:
                {post.fields.tags.map((tag) => {
                    return(<Link style={{ boxShadow: `none` }} to={`/tags/${tag}`}>
                    {tag}
                    </Link>)
                })}
            </p>
            <nav>
                <ul style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                }}>
                    <li>
                        {previous && (
                        <Link to={`/posts${previous.fields.slug}`} rel="prev">
                            ← {previous.frontmatter.title}
                        </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                        <Link to={`/posts${next.fields.slug}`} rel="next">
                            {next.frontmatter.title} →
                        </Link>
                        )}
                    </li>
                </ul>
            </nav>
    </BlogLayout>
    )
};

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                origin
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
            fields {
                slug
                tags
            }
        }
    }
`
